import type { SuperHubDocumentData } from '.';
import type { ReadChangelogType } from '@readme/api/src/mappings/changelog/types';
import type { CustomPageReadType } from '@readme/api/src/mappings/custompage/types';
import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';
import type { ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';
import type { PartialDeep } from 'type-fest';

/**
 * Returns whether the provided document is a guides page type or not.
 */
export function isGuidesPage(document?: PartialDeep<SuperHubDocumentData> | null): document is ReadGuideType['data'] {
  if (!document) return false;
  const guidesPage = document as ReadGuideType['data'];
  return ['link', 'basic'].includes(guidesPage.type ?? '') && !('api' in guidesPage);
}

/**
 * Returns whether the provided document is a reference page type or not.
 */
export function isReferencePage(
  document?: PartialDeep<SuperHubDocumentData> | null,
): document is ReadReferenceType['data'] {
  if (!document) return false;
  const referencePage = document as ReadReferenceType['data'];
  return 'api' in referencePage;
}

/**
 * Returns whether the provided document is a custom page type or not.
 */
export function isCustomPage(
  document?: PartialDeep<SuperHubDocumentData> | null,
): document is CustomPageReadType['data'] {
  if (!document) return false;
  const customPage = document as CustomPageReadType['data'];
  return ['html', 'markdown'].includes(customPage.content?.type ?? '');
}

/**
 * Returns whether the provided document is a changelog type or not.
 */
export function isChangelog(
  document?: PartialDeep<SuperHubDocumentData> | null,
): document is ReadChangelogType['data'] {
  if (!document) return false;
  const changelog = document as ReadChangelogType['data'];
  return ['none', 'added', 'fixed', 'improved', 'deprecated', 'removed'].includes(changelog.type ?? '');
}
