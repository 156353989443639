import type { GitSidebarCategory, GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { PageDocument } from '@readme/backend/models/page/types';

/**
 * Normalize for children pages between Git and Mongo pages.
 */
export function getChildrenPages(page: GitSidebarPage | PageDocument): GitSidebarPage[] | PageDocument[] {
  if ('pages' in page) {
    return page.pages;
  }
  return page.children as PageDocument[];
}

/**
 * Check if a page has visible, children pages.
 */
export function hasChildrenPages(childrenPages: GitSidebarPage[] | PageDocument[]): boolean {
  return (childrenPages || []).filter(child => !child.hidden).length > 0;
}

/**
 * Detect empty parent pages are pages with no body content that contain at least a single child.
 */
export function isEmptyParentPage(page: GitSidebarPage | PageDocument): boolean {
  return (
    ('isReference' in page && page.isReference
      ? // Only pages that are neither endpoints nor realtime pages should be considered empty
        page.type !== 'endpoint' && page.pageType !== 'RealtimePage'
      : page.type === 'basic') &&
    page.isBodyEmpty &&
    hasChildrenPages(getChildrenPages(page))
  );
}

/**
 * Recursively finds and returns the first child page that is not empty.
 */
export function findFirstContentfulChild<Page extends GitSidebarPage | PageDocument>(children: Page[]) {
  if (!children.length) return null;

  const firstChild = children[0];
  const firstChildPages = 'pages' in firstChild ? firstChild.pages : firstChild.children;
  if (firstChild.isBodyEmpty && firstChildPages?.length) {
    return findFirstContentfulChild(firstChildPages);
  }
  return firstChild;
}

/**
 * Recursively iterates over each sidebar page and finds the one that matches
 * the provided slug. Returns `null` if page was not found.
 * @example
 * ```ts
 * const page = findSidebarPageBySlug(sidebar, slug);
 * ```
 */
export function findSidebarPageBySlug(
  entities: (GitSidebarCategory | GitSidebarPage)[],
  slug: string,
): GitSidebarPage | null {
  for (let n = 0; n < entities.length; n += 1) {
    const entity = entities[n];
    if ('slug' in entity && entity.slug === slug) return entity;
    if ('pages' in entity) {
      const result = findSidebarPageBySlug(entity.pages, slug);
      if (result) return result;
    }
  }
  return null;
}

/**
 * Returns the first page in the sidebar. Excludes `hidden` and `link` pages by
 * default but can be overridden to include them using the `options` flags.
 * Returns `null` if not found.
 * @example
 * ```ts
 * const firstPage = findSidebarFirstPage(sidebar);
 * const firstPublicHiddenOrLinkPage = findSidebarFirstPage(sidebar, {
 *   includeHidden: true,
 *   includeLinks: true,
 * })
 * ```
 */
export function findSidebarFirstPage(
  sidebar: GitSidebarCategory[],
  options: {
    /** When true, includes hidden pages in the result. */
    includeHidden?: boolean;
    /** When true, includes link page types in the result. */
    includeLinks?: boolean;
  } = {},
) {
  for (let n = 0; n < sidebar.length; n += 1) {
    const category = sidebar[n];
    for (let k = 0; k < category.pages.length; k += 1) {
      const page = category.pages[k];
      const linkTest = options.includeLinks ? true : page.type !== 'link';
      const hiddenTest = options.includeHidden ? true : !page.hidden;
      if (linkTest && hiddenTest) {
        return page;
      }
    }
  }
  return null;
}

export * from './rdmd';
