import type { StateCreator } from 'zustand';

import base64url from 'base64url';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import qs from 'qs';

import { MyDevelopersSubrouteType } from '@core/enums/metrics';
import { actionLog } from '@core/store/util';
import type {
  ColumnOrder,
  DateRange,
  DateRangeKey,
  MetricsFilters,
  MyDevelopersRouteParams,
  Segment,
} from '@core/types/metrics';
import { omit } from '@core/utils/lodash-micro';

import { getSelectedDateRangeKey, type MetricsStore } from '..';
import { stringifyOptions } from '../constants';

export const DEFAULT_SORT_COLUMN = 'lastRequest';
export const DEFAULT_SORT_DIRECTION = 'desc';
export const DEFAULT_INSIGHTS_SORT_COLUMN = 'createdAt';

interface MyDevelopersSliceState {
  /** Decoded API key from route (using identifier param) */
  activeAPIKey: string;
  /** Active segment object from segments based on route segment slug */
  activeSegment: Segment | null;
  filters: MetricsFilters;
  /**
   * Whether or not current route includes /key and has identifier param
   * Set whenever route changes
   */
  hasAPIKeyInRoute: boolean;
  /**
   * Whether or not current route includes /segment and has identifier param
   * Set whenever route changes
   */
  hasSegmentInRoute: boolean;
  identifier?: MyDevelopersRouteParams['identifier'];
  isReady: boolean;
  segments: Segment[] | null;
  tableColumns: ColumnOrder[];
  type?: MyDevelopersRouteParams['type'];
}

interface MyDevelopersSliceAction {
  /**
   * Generate request path based on current filters
   * For /filters and /total endpoints
   */
  generateRequestPath: (endpoint: string, params: string) => string;

  /** Selector to check if there are active non-date range filters */
  getHasActiveNonDateRangeFilters: () => boolean;

  /** Selector to check if filters have changed from active segment */
  getHasChangedFromActiveSegment: () => boolean;

  /** Selector to check if filters have changed from initial state */
  getHasChangedFromInitialFilters: () => boolean;

  /** Selector to check if we have a matching segment based on route slug */
  getMatchingSegment: () => Segment | null;

  /**
   * Minimal query param string (excluding unnecessary params)
   * For /filters and /total endpoints
   */
  getMinimalQueryParams: () => string;

  /**
   * Selector for main active filters query param string
   * Used across all endpoints
   * */
  getQueryParams: () => string;

  /**
   * Date range specific filters query param string
   * For /filters, /total and /keyInsights endpoints
   */
  getRangeQueryParams: () => string;

  /**
   * Check if we're ready to fetch data
   * @param waitForSegments - Whether or not we should wait for segments to be fetched
   */
  getReadyToFetch: (options?: { waitForSegments?: boolean }) => boolean;

  /**
   * Segment query param string (excluding unnecessary params)
   * For /segments endpoint on creation and update
   */
  getSegmentQueryParams: () => string;

  /** Get selected date range key based on current filters */
  getSelectedDateRangeKey: () => string;

  /** Initialize My Developers store */
  initialize: () => void;

  /**
   *  Remove a specific filter from active filters when filter is a string[] value
   */
  removeFilter: (filterKey: string, filterValue: string) => void;

  /**
   * Reset filters (and segments)
   * @param maintainCurrentDateRange - Whether or not to maintain current date range filters when resetting
   * */
  resetFilters: (maintainCurrentDateFilters?: boolean) => void;

  /** Update active segment in store */
  updateActiveSegment: (segment: Segment) => void;

  /** Update the date range filters */
  updateDateRange: (key: DateRangeKey, customRange?: DateRange) => void;

  /**
   * Update the filters object w/ new filter params
   * @param isFromSegment - Whether or not filters are being updated from a segment navigation
   * */
  updateFilters: (newFilters: Partial<MetricsFilters>, isFromSegment?: boolean) => void;

  /** Update route params in store */
  updateRoute: (params: MyDevelopersRouteParams) => void;

  /** Update segments */
  updateSegments: (segments: Segment[]) => void;

  /** Update table columns in store (from RecentRequestTable or active segment) */
  updateTableColumns: (columns: ColumnOrder[]) => void;
}

export interface MyDevelopersSlice {
  /**
   * State slice containing fields and actions that are relevant to My Developers pages.
   */
  myDevelopers: MyDevelopersSliceAction & MyDevelopersSliceState;
}

const initialState: MyDevelopersSliceState = {
  activeAPIKey: '',
  activeSegment: null,
  filters: {
    demo: false,
    direction: DEFAULT_SORT_DIRECTION,
    includeTrends: true,
    method: [],
    page: 0,
    pageSize: 30,
    path: [],
    rangeEnd: null,
    rangeLength: 24,
    rangeStart: null,
    resolution: 'hour',
    sort: DEFAULT_SORT_COLUMN,
    status: [],
    useragent: [],
    userSearch: null,
  },
  hasAPIKeyInRoute: false,
  hasSegmentInRoute: false,
  isReady: false,
  segments: null,
  tableColumns: [],
};

/**
 * MetricsStore state slice containing data relevant to MyDevelopers page
 */
export const createMyDevelopersSlice: StateCreator<
  MetricsStore & MyDevelopersSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  MyDevelopersSlice
> = (set, get) => {
  /**
   * Holds reference to the initial state so we can support resetting the
   * store back to this state when calling `reset()`.
   */
  const resetState = {
    ...initialState,
  };

  return {
    myDevelopers: {
      ...resetState,

      generateRequestPath: (endpoint, params) => {
        const { hasAPIKeyInRoute, activeAPIKey } = get().myDevelopers;

        return hasAPIKeyInRoute
          ? `${endpoint}?groupId=${encodeURIComponent(activeAPIKey)}&includeUpdatedUA=true&${params}`
          : `${endpoint}?${params}`;
      },

      getHasChangedFromActiveSegment: () => {
        const { activeSegment, identifier, tableColumns } = get().myDevelopers;
        const queryParams = get().myDevelopers.getQueryParams();

        if (!activeSegment || activeSegment.slug !== identifier) return false;

        const keysToOmit = ['page', 'pageSize', 'includeTrends', 'demo'];

        const activeSegmentSearch = qs.parse(activeSegment.search) as Partial<MetricsFilters>;
        const queryParamsSearch = qs.parse(queryParams) as Partial<MetricsFilters>;

        // Array comparison needs to be order agnostic, so we need to sort before comparing
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const customizer = (value1: any, value2: any) => {
          if (Array.isArray(value1) && Array.isArray(value2)) {
            const sorted1 = sortBy(value1);
            const sorted2 = sortBy(value2);
            return isEqual(sorted1, sorted2);
          }

          return undefined;
        };

        const filtersHaveChanged = !isEqualWith(
          omit(activeSegmentSearch, keysToOmit),
          omit(queryParamsSearch, keysToOmit),
          customizer,
        );

        // Active segment.columns includes _id which we can skip diffing against
        const activeSegmentColumns = activeSegment.columns.map(col => {
          return { columnId: col.columnId, visible: col.visible, order: col.order };
        });

        const tableColumnsHaveChanged = !isEqual(tableColumns, activeSegmentColumns);

        return filtersHaveChanged || tableColumnsHaveChanged;
      },

      getHasChangedFromInitialFilters: () => {
        const keysToOmit = ['page'];
        return !isEqual(omit(get().myDevelopers.filters, keysToOmit), omit(resetState.filters, keysToOmit));
      },

      getHasActiveNonDateRangeFilters: () => {
        const keysToOmit = ['page', 'pageSize', 'rangeLength', 'resolution', 'rangeStart', 'rangeEnd'];
        return !isEqual(omit(get().myDevelopers.filters, keysToOmit), omit(resetState.filters, keysToOmit));
      },

      getMatchingSegment: () => {
        const { hasSegmentInRoute, segments, identifier } = get().myDevelopers;
        if (!hasSegmentInRoute || segments === null) return null;

        const matchingSegment = segments.find(s => s.slug === identifier);

        return matchingSegment || null;
      },

      getSegmentQueryParams: () => {
        const segmentFilters = pick(get().myDevelopers.filters, [
          'path',
          'method',
          'status',
          'useragent',
          'sort',
          'direction',
          'includeTrends',
          'rangeLength',
          'rangeStart',
          'rangeEnd',
          'resolution',
        ]) as Partial<MetricsFilters>;

        segmentFilters.userSearch = get().myDevelopers.filters.userSearch || undefined;

        return qs.stringify(segmentFilters, stringifyOptions);
      },

      getSelectedDateRangeKey: () => {
        // Reference dateRanges from MetricsStore
        const { dateRanges } = get();

        return getSelectedDateRangeKey({
          dateRanges,
          rangeLength: Number(get().myDevelopers.filters.rangeLength) || 0,
          resolution: get().myDevelopers.filters.resolution || '',
        });
      },

      getQueryParams: () => {
        return qs.stringify(get().myDevelopers.filters, stringifyOptions);
      },

      getMinimalQueryParams: () => {
        const keysToOmit = ['direction', 'includeTrends', 'page', 'pageSize', 'sort'];
        return qs.stringify(omit(get().myDevelopers.filters, keysToOmit), stringifyOptions);
      },

      getRangeQueryParams: () => {
        const { rangeLength, rangeStart, rangeEnd, resolution, timezone, demo } = get().myDevelopers.filters;
        const filters = { rangeLength, rangeStart, rangeEnd, resolution, timezone, demo };
        return qs.stringify(filters, stringifyOptions);
      },

      getReadyToFetch: (options: { waitForSegments?: boolean } = {}) => {
        const { activeSegment, hasSegmentInRoute, isReady } = get().myDevelopers;
        const { waitForSegments } = options;

        // Wait for myDevelopers slice to be initialized
        if (!isReady) return false;

        // Segments are ready when we have a matching segment and active segment OR
        // when there's been no match found (matchingSegmentInRoute is null)
        if (waitForSegments && hasSegmentInRoute) {
          return !!activeSegment;
        }

        return true;
      },

      initialize: () => {
        set(
          state => {
            const customerUsage = state.customerUsage;
            const { rangeLength, resolution } = state.query;
            const { type } = state.myDevelopers;

            const sort = type === MyDevelopersSubrouteType.Key ? DEFAULT_INSIGHTS_SORT_COLUMN : DEFAULT_SORT_COLUMN;

            // Check if we should show demo data based on recent usage
            let hasRecentUsage = false;

            if (customerUsage?.paying) {
              hasRecentUsage = !!((customerUsage?.explorer?.thirtyDay || 0) + (customerUsage?.sdk?.thirtyDay || 0));
            } else {
              hasRecentUsage = !!(
                (customerUsage?.explorer?.twentyFourHour || 0) + (customerUsage?.sdk?.twentyFourHour || 0)
              );
            }

            // Update the initialStates's initial resolution, rangeLength and sort from query params
            // since we use this for comparisons later
            resetState.filters = {
              ...resetState.filters,
              rangeLength,
              resolution,
              sort,
              // Show demo data if user has no recent usage
              demo: !hasRecentUsage,
            };

            state.myDevelopers.filters = {
              ...state.myDevelopers.filters,
              ...resetState.filters,
            };

            state.myDevelopers.isReady = true;
          },
          false,
          actionLog('myDevelopers.initialize'),
        );
      },

      removeFilter: (filterKey, filterValue) => {
        set(
          state => {
            const currentFilterValues = state.myDevelopers.filters[filterKey] as string[];

            if (currentFilterValues) {
              const index = currentFilterValues.indexOf(filterValue);
              if (index !== -1) {
                currentFilterValues.splice(index, 1);
              }
            }

            if (state.myDevelopers.filters.page !== 0) {
              state.myDevelopers.filters.page = 0;
            }
          },
          false,
          actionLog('myDevelopers.removeFilter', {
            filterKey,
            filterValue,
          }),
        );
      },

      resetFilters: (maintainCurrentDateFilters = false) => {
        set(
          state => {
            state.myDevelopers.segments = state.myDevelopers.segments || [];
            state.myDevelopers.activeSegment = null;
            state.myDevelopers.filters = {
              ...resetState.filters,
              ...(maintainCurrentDateFilters
                ? {
                    rangeLength: state.myDevelopers.filters.rangeLength,
                    resolution: state.myDevelopers.filters.resolution,
                    rangeStart: state.myDevelopers.filters.rangeStart,
                    rangeEnd: state.myDevelopers.filters.rangeEnd,
                  }
                : {}),
            };
          },
          false,
          actionLog('myDevelopers.resetFilters'),
        );
      },

      updateDateRange: (key: DateRangeKey, customRange?: DateRange) => {
        set(
          state => {
            // Reference dateRanges from MetricsStore
            const dateRanges = state.dateRanges;

            let filters = {};

            if (key === 'custom' && customRange) {
              filters = { ...customRange, page: 0 };
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { enabled, ...rest } = dateRanges[key];
              // When selecting a non-custom/set date range, clear any custom range params by setting them to null
              filters = { ...rest, rangeStart: null, rangeEnd: null, page: 0 };
            }

            state.myDevelopers.filters = {
              ...state.myDevelopers.filters,
              ...filters,
            };
          },
          false,
          actionLog('myDevelopers.updateDateRange', { key, customRange }),
        );
      },

      updateFilters: newFilters => {
        set(
          state => {
            Object.entries(newFilters).forEach(([key, value]) => {
              if (value === null) {
                delete state.myDevelopers.filters[key];
              } else {
                state.myDevelopers.filters[key] = value;
              }
            });
          },
          false,
          actionLog('myDevelopers.updateFilters', newFilters),
        );
      },
      updateRoute: ({ type, identifier }) => {
        set(
          state => {
            // Note: because these state values are only ever updated when the route changes,
            // we don't need to use selectors for them and can just set them directly
            const hasAPIKeyInRoute = type === MyDevelopersSubrouteType.Key && !!identifier;

            state.myDevelopers.type = type;
            state.myDevelopers.identifier = identifier;
            state.myDevelopers.hasAPIKeyInRoute = hasAPIKeyInRoute;
            state.myDevelopers.hasSegmentInRoute = type === MyDevelopersSubrouteType.Segment && !!identifier;
            state.myDevelopers.activeAPIKey = hasAPIKeyInRoute && identifier ? base64url.decode(identifier) : '';
          },
          false,
          actionLog('myDevelopers.updateRoute', { type, identifier }),
        );
      },

      updateActiveSegment: segment => {
        set(
          state => {
            state.myDevelopers.activeSegment = segment;
            const activeSegmentParams = qs.parse(segment.search) as Partial<MetricsFilters>;
            const activeSegmentTableColumns = segment.columns.map(col => {
              // We need to omit the _id field from the columns
              return { columnId: col.columnId, visible: col.visible, order: col.order };
            });

            state.myDevelopers.filters = {
              ...resetState.filters,
              ...activeSegmentParams,
              // We should clear userSearch if it's not present in the activeSegmentParams
              userSearch: activeSegmentParams.userSearch ?? null,
            };

            state.myDevelopers.tableColumns = activeSegmentTableColumns;
          },
          false,
          actionLog('myDevelopers.updateActiveSegment', segment),
        );
      },

      updateSegments: segments => {
        set(
          state => {
            state.myDevelopers.segments = segments;
          },
          false,
          actionLog('myDevelopers.updateSegments', segments),
        );
      },

      updateTableColumns: columns => {
        set(
          state => {
            state.myDevelopers.tableColumns = columns;
          },
          false,
          actionLog('myDevelopers.updateTableColumns', columns),
        );
      },
    },
  };
};

export * from './InitializeMyDevelopers';
