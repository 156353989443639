import type { ReferenceStore } from '..';
import type { AuthForHAR } from '@readme/oas-to-har/lib/types';
import type Oas from 'oas';
import type { Extensions } from 'oas/extensions';
import type { Operation } from 'oas/operation';
import type { StateCreator } from 'zustand';

import { getGroupNameById } from '@readme/iso';
// eslint-disable-next-line readme-internal/no-restricted-imports
import { maskCredential } from '@readme/server-shared/metrics/mask-credential';

import type { VariablesContextValue } from '@core/context';
import { actionLog } from '@core/store/util';

import {
  supportedOAuthFlows,
  type OAuthFlowClientState,
  type OAuthSchemeExtended,
  type SupportedOAuthFlow,
} from './oauth-types';

export interface ReferenceAuthSliceState {
  /**
   * An object containing the currently populated authentication credentials
   * for the current operation(?)
   */
  auth: AuthForHAR;

  /**
   * For logged in users, this is the currently selected group (e.g., API credentials)
   */
  group?: string;

  /**
   * For logged in users, this is the name of the currently selected group
   */
  groupName?: boolean | string;

  /**
   * For logged in users, this array contains all groups (e.g., API credentials) available
   * for the current user.
   */
  groups?: {
    id: string;
    name: number | string;
  }[];

  /**
   * An hashed version of the group, which is stored in the user's local storage.
   */
  hashedGroup?: string;

  /**
   * Is the current user logged in?
   */
  isGroupLoggedIn: boolean;

  /**
   * All data related to OAuth flows.
   */
  oauth: {
    /**
     * A map of all the current security schemes that we've initialized OAuth flows for.
     */
    schemes: Record<string, OAuthSchemeExtended>;
  };

  /**
   * For operations that contain multiple forms of auth, this array contains the ones that are currently selected.
   * The strings in this array correspond to the keys of the `auth` object.
   */
  selectedAuth: string[];
}

export interface ReferenceAuthSliceActions {
  /**
   * Initializes the store. This generally runs every time a new endpoint is selected.
   */
  initialize: (
    opts: Pick<
      ReferenceAuthSliceState,
      'auth' | 'group' | 'groupName' | 'groups' | 'isGroupLoggedIn' | 'oauth' | 'selectedAuth'
    > & { apiDefinition?: Oas; isOAuthRedirectPage?: boolean; operation?: Operation },
  ) => void;

  /**
   * For a given security scheme and scope, toggles whether or not the scope is selected.
   */
  toggleOAuthScope: (securitySchemeKey: string, scope: string) => void;

  /**
   * Updates the `auth` object.
   * By default, the auth passed in is prioritized over the existing auth object.
   */
  updateAuth: (newAuth: ReferenceAuthSliceState['auth'], prioritizeNewAuth?: boolean) => void;

  /**
   * Updates the `group` selection.
   */
  updateGroup: ({
    apiDefinition,
    groupId,
    user,
  }: {
    apiDefinition: Oas;
    groupId: ReferenceAuthSliceState['group'];
    user: VariablesContextValue['user'];
  }) => void;

  /**
   * For a given security scheme, updates the client ID or client secret.
   */
  updateOAuthClient: (
    securitySchemeKey: string,
    values:
      | { clientId: OAuthFlowClientState['clientId']; type: 'id' }
      | { clientSecret: OAuthFlowClientState['clientSecret']; type: 'secret' },
  ) => void;

  /**
   * For a given security scheme, updates the currently selected OAuth flow (e.g., `authorizationCode`).
   */
  updateOAuthFlow: (securitySchemeKey: string, selectedFlow: SupportedOAuthFlow) => void;

  /**
   * Updates the OAuth state/status for a given security scheme.
   */
  updateOAuthStatus: (
    securitySchemeKey: string,
    status: Partial<OAuthFlowClientState> & Required<Pick<OAuthFlowClientState, 'state' | 'status'>>,
  ) => void;

  /**
   * Updates the `selectedAuth` array.
   */
  updateSelectedAuth: (nextSelectedAuth: ReferenceAuthSliceState['selectedAuth']) => void;
}

export interface ReferenceAuthSlice {
  /**
   * State slice containing fields and actions that are relevant when reading/writing
   * auth data in the Reference. This slice includes data about the logged in user.
   */
  auth: ReferenceAuthSliceActions & ReferenceAuthSliceState;
}

export const initialState: ReferenceAuthSliceState = {
  auth: {},
  selectedAuth: [],
  isGroupLoggedIn: false,
  oauth: {
    schemes: {},
  },
};

/**
 * Auth state slice containing fields related to API authentication.
 */
export const createReferenceAuthSlice: StateCreator<
  ReferenceAuthSlice & ReferenceStore,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  ReferenceAuthSlice
> = (set, get) => ({
  auth: {
    ...initialState,

    initialize: opts => {
      const isReady = get().isReady;

      const schemes = structuredClone(opts.oauth.schemes);

      const clearedOAuth = {
        ...opts.oauth,
        schemes: Object.fromEntries(
          Object.entries(schemes).map(([schemeKey, scheme]) => {
            // When we're on the original page, we want to reset the status on initialization.
            // However, when we're on the OAuth redirect page, we do not want to overwrite
            // the status sent from the original page.
            if (!opts.isOAuthRedirectPage) {
              scheme.draftState = {
                ...scheme.draftState,
                state: '',
                status: 'not-started',
              };
            }

            return [schemeKey, scheme];
          }),
        ),
      };

      const { apiDefinition, operation } = opts;

      const oauthOptions =
        apiDefinition && operation
          ? (apiDefinition.getExtension('oauth-options', operation) as Extensions['oauth-options'])
          : undefined;

      set(
        state => {
          // we only initialize certain parts of the store on initial page load
          if (!isReady) {
            state.auth.auth = opts.auth;
            state.auth.group = opts.group;
            state.auth.groupName = opts.groupName;
            state.auth.groups = opts.groups;
            state.auth.hashedGroup = maskCredential(opts.group);
            state.auth.isGroupLoggedIn = opts.isGroupLoggedIn;
            state.auth.oauth = clearedOAuth;
            state.auth.selectedAuth = opts.selectedAuth;
          }

          // if we have an API definition and operation, we'll initialize the OAuth schemes
          // (this also happens on subsequent page navigations since the operation may change)
          if (apiDefinition && operation) {
            const securitySchemes = operation.prepareSecurity().OAuth2;

            (securitySchemes || []).forEach(scheme => {
              let requiredScopes: OAuthSchemeExtended['requiredScopes'];
              // Note: this logic might become an issue if the current operation has multiple security schemes that use the same OAuth2 scheme,
              // but we'll cross that absolutely bonkers bridge when we get there.
              const topLevelScopes = apiDefinition?.api?.security?.find(s => Object.keys(s).includes(scheme._key));
              const operationLevelScopes = operation?.schema?.security?.find(s => Object.keys(s).includes(scheme._key));

              // We will use any operation-level scopes if they exist
              // If not, we'll use any top-level scopes
              if (operationLevelScopes) {
                requiredScopes = operationLevelScopes[scheme._key];
              } else if (topLevelScopes && !operationLevelScopes) {
                requiredScopes = topLevelScopes[scheme._key];
              }

              if (
                scheme._key &&
                scheme.type === 'oauth2' && // this is mostly redundant, used as a type-guard
                !state.auth.oauth.schemes[scheme._key]
              ) {
                const firstSupportedFlow = Object.keys(scheme.flows).find(flow =>
                  Object.keys(supportedOAuthFlows).includes(flow),
                ) as SupportedOAuthFlow | undefined;

                if (firstSupportedFlow) {
                  const schemeToWrite: OAuthSchemeExtended = {
                    draftState: {
                      clientId: '',
                      clientSecret: '',
                      redirectUri: '',
                      selectedFlow: firstSupportedFlow,
                      selectedScopes: [],
                      state: '',
                      status: 'not-started',
                    },
                    flows: {},
                    proxyEnabled: apiDefinition.getExtension('proxy-enabled', operation) as boolean | undefined,
                    requiredScopes,
                    scopeSeparator: oauthOptions?.scopeSeparator || ' ',
                    useInsecureClientAuthentication: oauthOptions?.useInsecureClientAuthentication || false,
                  };

                  if (scheme.flows.authorizationCode) {
                    // OpenAPI 3.0 allows for relative URLs in these fields.
                    // If they're' complete URLs, pass them through.
                    // Otherwise, tack on current server URL.
                    // See: https://swagger.io/docs/specification/authentication/oauth2/#relative-url
                    const [authorizationUrl, tokenUrl] = [
                      scheme.flows.authorizationCode.authorizationUrl,
                      scheme.flows.authorizationCode.tokenUrl,
                    ].map(url => (URL.canParse(url) ? url : `${state.form.fullServerUrl}${url}`));

                    schemeToWrite.flows.authorizationCode = {
                      ...scheme.flows.authorizationCode,
                      authorizationUrl,
                      tokenUrl,
                    };
                  }

                  if (scheme.flows.clientCredentials) {
                    const [tokenUrl] = [scheme.flows.clientCredentials.tokenUrl].map(url =>
                      URL.canParse(url) ? url : `${state.form.fullServerUrl}${url}`,
                    );

                    schemeToWrite.flows.clientCredentials = {
                      ...scheme.flows.clientCredentials,
                      tokenUrl,
                    };
                  }

                  state.auth.oauth.schemes[scheme._key] = schemeToWrite;
                }
              }

              if (
                // If the scheme exists and there are any required scopes, let's set `requiredScopes`
                scheme._key &&
                scheme.type === 'oauth2' && // this is mostly redundant, used as a type-guard
                state.auth.oauth.schemes[scheme._key] &&
                (topLevelScopes || operationLevelScopes)
              ) {
                state.auth.oauth.schemes[scheme._key].requiredScopes = requiredScopes;
              }
            });
          }
        },
        false,
        actionLog('initialize auth slice', opts),
      );
    },

    toggleOAuthScope: (securitySchemeKey, scope) => {
      const scheme = get().auth.oauth.schemes[securitySchemeKey];
      const index = scheme?.draftState.selectedScopes.indexOf(scope);

      if (scheme?.draftState.status === 'failure' || scheme?.draftState.status === 'pending') {
        get().auth.updateOAuthStatus(securitySchemeKey, { state: '', status: 'not-started' });
      }

      set(
        state => {
          if (scheme) {
            if (index >= 0) {
              state.auth.oauth.schemes[securitySchemeKey].draftState.selectedScopes.splice(index, 1);
            } else {
              state.auth.oauth.schemes[securitySchemeKey].draftState.selectedScopes.push(scope);
            }
          }
        },
        false,
        actionLog('toggleOAuthScope', { securitySchemeKey, scope }),
      );
    },

    updateAuth: (newAuth, prioritizeNewAuth = true) => {
      set(
        state => {
          if (prioritizeNewAuth) {
            state.auth.auth = { ...state.auth.auth, ...newAuth };
          } else {
            state.auth.auth = { ...newAuth, ...state.auth.auth };
          }
        },
        false,
        actionLog('updateAuth', { newAuth, prioritizeNewAuth }),
      );
    },

    updateGroup: ({ apiDefinition, groupId: nextGroupId, user }) => {
      set(
        state => {
          if (nextGroupId === state.auth.group) {
            return;
          }

          const nextGroupName = getGroupNameById(user?.keys, nextGroupId);
          state.auth.group = nextGroupId;
          state.auth.hashedGroup = maskCredential(nextGroupId);
          state.auth.groupName = nextGroupName;

          if (nextGroupName) {
            const nextAuth = apiDefinition.getAuth(user || {}, nextGroupName) as ReferenceAuthSliceState['auth'];
            state.auth.auth = { ...state.auth.auth, ...nextAuth };
          }
        },
        false,
        actionLog('updateGroup', { apiDefinition, nextGroupId }),
      );
    },

    updateOAuthClient: (securitySchemeKey, values) => {
      const scheme = get().auth.oauth.schemes[securitySchemeKey];

      if (scheme.draftState.status === 'failure' || scheme.draftState.status === 'pending') {
        get().auth.updateOAuthStatus(securitySchemeKey, { state: '', status: 'not-started' });
      }

      set(
        state => {
          state.auth.oauth.schemes[securitySchemeKey].draftState = {
            ...state.auth.oauth.schemes[securitySchemeKey].draftState,
            ...(values.type === 'id' ? { clientId: values.clientId } : {}),
            ...(values.type === 'secret' ? { clientSecret: values.clientSecret } : {}),
          };
        },
        false,
        actionLog('updateOAuthClient', { securitySchemeKey, values }),
      );
    },

    updateOAuthFlow: (securitySchemeKey, selectedFlow) => {
      set(
        state => {
          state.auth.oauth.schemes[securitySchemeKey].draftState.selectedFlow = selectedFlow;
        },
        false,
        actionLog('updateOAuthFlow', { securitySchemeKey, selectedFlow }),
      );
    },

    updateOAuthStatus: (securitySchemeKey, status) => {
      // if the response is successful, also update the standard auth object accordingly
      if (status.status === 'done' && status.response?.access_token) {
        get().auth.updateAuth({ [securitySchemeKey]: status.response.access_token });
      }

      const expiresAt =
        status.status === 'done' && status.response?.expires_in
          ? Date.now() / 1000 + status.response.expires_in
          : undefined;

      let selectedScopes = status.selectedScopes || [];

      // if the response is successful and contains scope info, parse it and store it
      // see: https://datatracker.ietf.org/doc/html/rfc6749#section-3.3
      if (status.status === 'done' && status.response?.scope) {
        try {
          selectedScopes = status.response.scope.split(get().auth.oauth.schemes[securitySchemeKey].scopeSeparator);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error parsing scopes from OAuth response:', status.response.scope);
        }
      }

      set(
        state => {
          state.auth.oauth.schemes[securitySchemeKey].draftState = {
            ...state.auth.oauth.schemes[securitySchemeKey].draftState,
            response: undefined,
            // @ts-expect-error clearing out response data
            error: undefined,
            error_description: undefined,
            ...status,
            // add a timestamp to the status, if `expires_in` exists
            expires_at: expiresAt,
          };

          if (status.status === 'done') {
            state.auth.oauth.schemes[securitySchemeKey].finalState = {
              ...state.auth.oauth.schemes[securitySchemeKey].finalState,
              // @ts-expect-error clearing out response data
              response: undefined,
              error: undefined,
              error_description: undefined,
              ...status,
              // add a timestamp to the status, if `expires_in` exists
              expires_at: expiresAt,
              selectedScopes,
            };
          }
        },
        false,
        actionLog('updateOAuthStatus', { securitySchemeKey, status }),
      );
    },

    updateSelectedAuth: nextSelectedAuth => {
      set(
        state => {
          state.auth.selectedAuth = nextSelectedAuth;
        },
        false,
        actionLog('updateSelectedAuth', nextSelectedAuth),
      );
    },
  },
});
