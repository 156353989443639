import { create as createHash, parse as parseHash } from '@readme/ssri';

/* This will generate an integrity hash that looks something like this:
 *
 * sha512-Naxska/M1INY/thefLQ49sExJ8E+89Q2bz/nC4Pet52iSRPtI9w3Cyg0QdZExt0uUbbnfMJZ0qTabiLJxw6Wrg==?1345
 *
 * With the last 4 digits on the end for us to use to identify it later in a list.
 *
 * This function should match:
 * https://github.com/readmeio/metrics-sdks/blob/1458c2f43f5bd9ecea2f0419c2d14574bdfa25c5/packages/node/src/lib/construct-payload.ts#L114-L127=
 *
 * Taken from code written by @domharrington, with <3
 */
export const maskCredential = (credential?: number | string): string => {
  if (!credential) return '';

  const castCredential = credential.toString();
  const transparentPortion = castCredential.slice(-4);

  return createHash(castCredential, {
    algorithm: 'sha512',
    options: [transparentPortion],
  }).toString();
};

/**
 * Validates that incoming apiKey is hashed
 * Using ReadMe's SSRI algo
 */
export const checkHash = (credential?: string): boolean => {
  // If there's no value, we cannot verify its hash
  if (!credential) return false;

  // If credential, attempt to parse if via our SSRI package
  const parsedCredential = parseHash(credential);
  if (!parsedCredential) return false;

  const { algorithm, options } = parsedCredential;
  // Check that hashing algorithm is sha512 and
  // "exposed" characters post querystring exist and are within expected range
  return algorithm === 'sha512' && options?.[0]?.length <= 4;
};
