import type { MyDevelopersSlice } from './MyDevelopers';
import type { MetricsPageConfig } from './types';

import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';
import { createStore } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type { ProjectContextValue } from '@core/context';
import type { DateRangeKey, DateRangeOptions, MetricsFilters } from '@core/types/metrics';

import type { CustomerUsageData, CustomerUsageState } from '@routes/Dash/Project/Metrics/types/props';

import { actionLog, createBoundedUseStore, isClient } from '../util';

import { dateRangeDefaults } from './constants';
import { createMyDevelopersSlice } from './MyDevelopers';

// If a customer is not paying us for metrics, block their usage of all ranges besides last 24 hours
const payingRequired: DateRangeKey[] = ['week', 'month', 'quarter', 'year'];

/**
 * Helper function to get the selected date range key consistently from the date range options
 * Used in MetricsStore (and MyDevelopers slice)
 */
export const getSelectedDateRangeKey = ({
  dateRanges,
  rangeLength,
  resolution,
}: {
  dateRanges: DateRangeOptions;
  rangeLength?: number | null;
  resolution?: string | null;
}) => {
  return (
    Object.keys(dateRanges).find(key => {
      const val = dateRanges[key as DateRangeKey];
      return val.resolution === resolution && val.rangeLength === rangeLength;
    }) || 'custom'
  );
};

export type QueryType = 'graphQuery' | 'query' | 'tableQuery';

interface MetricsStoreState {
  /**
   * Customer usage data
   */
  customerUsage: CustomerUsageState;

  /** Available date ranges based on customer usage */
  dateRanges: DateRangeOptions;

  /** Whether to include development data */
  developmentData: boolean;

  /**
   * The query parameters object used to build graph query fetchers
   */
  graphQuery: Partial<MetricsFilters>;

  /**
   * Internal flag to determine if the store has been hydrated from local storage
   */
  hasHydrated: boolean;

  /** Whether to include Try It Now data */
  includeTryItNow: boolean;

  /**
   * Indicates that the store has been initialized to its beginning state via
   * `initialize()` action and lets connected subscribers know that it is ready
   * for consumption.
   * @see initialize
   */
  isReady: boolean;

  /**
   * Whether user has completed setup (comes from Project context)
   * Defaults to true to prevent flashes for setup banners/CTAs components
   */
  isSetupComplete: boolean;

  /**
   * Whether or not fetched /requests/usage data is ready
   * Used to gate some metrics requests until usage data is ready
   */
  isUsageReady: boolean;

  /**
   * Metrics page configuration - used to store page-specific settings
   * like graph, table, and filter options.
   * (ex. @routes/Dash/Project/Metrics/routes/APICalls.tsx)
   */
  metricsPageConfig: MetricsPageConfig | Record<string, never>;

  /** Current pathname */
  pathname: string;

  /** Previous pathname */
  prevPathname: string;

  /**
   * The query parameters object used to build fetchers
   */
  query: Partial<MetricsFilters>;

  /** Current search */
  search: string;

  /** Selected date range key for top nav date range picker */
  selectedDateRangeKey: string;

  /**
   * The query parameters object used to build table query fetchers
   */
  tableQuery: Partial<MetricsFilters>;
}

interface MetricsStoreAction {
  /**
   * Initializes the store based on the provided settings. In order to enable
   * SSR, this needs to be called inline and high up in the rendering tree to
   * make the store's state ready before rendering components downstream during
   * both SSR and the initial CSR.
   */
  initialize: (settings: {
    project: {
      metrics: ProjectContextValue['project']['metrics'];
      onboardingCompleted: ProjectContextValue['project']['onboardingCompleted'];
    };
    usage: CustomerUsageData;
  }) => void;

  /**
   * Resets state back to the initial default state.
   */
  reset: () => void;

  /**
   * Resets the specified query object
   */
  resetQuery: (queryType: QueryType) => void;

  /**
   * Whether to limit date ranges based on customer usage
   * if they're not paying or over their limit
   * @returns boolean
   */
  shouldLimitDateRanges: () => boolean;

  /**
   * Update the available date ranges based on customer usage
   */
  updateDateRanges: () => void;

  /** Set the development data state */
  updateDevelopmentData: (newState: boolean) => void;

  /**
   * Update the hasHydrated state
   */
  updateHasHydrated: (state: boolean) => void;

  /** Set the include Try It Now state */
  updateIncludeTryItNow: (newState: boolean) => void;

  /**
   * Update the metrics page configuration
   */
  updateMetricsPageConfig: (nextConfig: MetricsPageConfig) => void;

  /**
   *  Update the specified query object with new query params
   */
  updateQuery: (query: QueryType, newQuery: Partial<MetricsFilters>) => void;

  /**
   * Updates the current route we've currently navigated to with the provided
   * URL pathname and search properties. This is used to keep track of the
   * current route in the store for use in other actions.
   * prevPathname is used to track the previous pathname using usePrevious() hook
   * @see InitializeMetricsStore
   */
  updateRoute: (route: { pathname: string; prevPathname: string; search: string }) => void;

  /**
   * Update the selected date range key based on the current query params
   */
  updateSelectedDateRangeKey: () => void;
}

export type MetricsStore = MetricsStoreAction & MetricsStoreState & MyDevelopersSlice;

const initialState: MetricsStoreState = {
  customerUsage: {
    explorer: {
      monthToDate: 0,
      thirtyDay: 0,
      twentyFourHour: 0,
    },
    limit: 0,
    overLimit: false,
    paying: false,
    sdk: {
      monthToDate: 0,
      thirtyDay: 0,
      twentyFourHour: 0,
    },
  },
  dateRanges: dateRangeDefaults,
  developmentData: false,
  graphQuery: {},
  hasHydrated: false,
  includeTryItNow: true,
  isReady: false,
  isSetupComplete: true,
  isUsageReady: false,
  metricsPageConfig: {},
  pathname: '',
  prevPathname: '',
  query: {
    pageSize: 30,
    rangeLength: 30,
    resolution: 'day',
  },
  search: '',
  selectedDateRangeKey: '',
  tableQuery: {},
};

/**
 * Store that contains all application-level state data required by Metrics.
 * This store can be accessed and used primarily in the Dash (and the Hub soon!). React components
 * should call `useMetricsStore()` instead.
 * @example
 * import { metricsStore } from '@core/store';
 *
 * const dateRanges = metricsStore.getState().dateRanges;
 */
export const metricsStore = createStore<MetricsStore>()(
  devtools(
    immer(
      persist(
        (set, get, ...props) => {
          /**
           * Holds reference to the initial state so we can support resetting the
           * store back to this state when calling `reset()`.
           */
          const resetState = {
            ...initialState,
            ...createMyDevelopersSlice(set, get, ...props),
          };

          return {
            ...resetState,

            initialize: settings => {
              const { project, usage } = settings;

              set(
                state => {
                  state.isReady = isClient;

                  // Fallback order for prefs here: hydrated store values (from LocalStorage persist middleware) || defaults
                  state.developmentData = get().developmentData ?? false;
                  state.includeTryItNow = get().includeTryItNow ?? true;

                  // We need to initialize store with customer/company usage data
                  // so we can gate some metrics requests until usage data is ready
                  const { sdk } = usage;
                  const limit = project?.metrics?.monthlyLimit || 0;

                  state.customerUsage = {
                    limit,
                    overLimit: limit > 0 && sdk?.monthToDate > limit,
                    paying: limit > 0,
                    ...usage,
                  };

                  // Mark customer usage as ready so fetchers can proceed
                  state.isUsageReady = true;

                  // Whether to show setup banners/CTAs
                  state.isSetupComplete = project?.onboardingCompleted.logs;

                  // Update the query object with defaults and development data/include try it now flags
                  state.query = {
                    ...state.query,
                    development: state.developmentData,
                    tryItNow: state.includeTryItNow,
                  };
                },
                false,
                actionLog('initialize', settings),
              );

              // Update the date ranges, and selected key after initializing the store
              get().updateSelectedDateRangeKey();
              get().updateDateRanges();
            },

            reset: () => {
              set(resetState, false, actionLog('reset'));
            },

            resetQuery: queryType =>
              set(
                state => {
                  if (!['graphQuery', 'query', 'tableQuery'].includes(queryType)) {
                    throw new Error('Invalid queryType provided to resetQuery');
                  }

                  state[queryType] = {};
                },
                false,
                actionLog('resetQuery', queryType),
              ),

            shouldLimitDateRanges: () => {
              const { paying, overLimit } = get().customerUsage;
              const pathname = get().pathname;

              const notPayingOrOverlimit = !paying || overLimit;

              const isMyDevelopers = pathname.includes('metrics/developers');
              const isAPIMetricsPage = get().metricsPageConfig.endpoint === 'requests';

              /**
               * For API Metrics pages + My Developers:
               * Limit Date Ranges to 24 hours if customer isn't paying, or
               * If they're paying but their over their purchased limit
               */
              return notPayingOrOverlimit && (isMyDevelopers || isAPIMetricsPage);
            },

            updateHasHydrated: state => {
              set(
                s => {
                  s.hasHydrated = state;
                },
                false,
                actionLog('updateHasHydrated', state),
              );
            },

            updateDateRanges: () => {
              set(
                state => {
                  const shouldLimitDateRanges = get().shouldLimitDateRanges();
                  const dateRanges = payingRequired.reduce(
                    (acc, resolution) => {
                      acc[resolution].enabled = !shouldLimitDateRanges;
                      return acc;
                    },
                    cloneDeep(dateRangeDefaults) as DateRangeOptions,
                  );

                  state.dateRanges = dateRanges;
                },
                false,
                actionLog('updateDateRanges'),
              );
            },

            updateDevelopmentData: (newState: boolean) => {
              set(
                state => {
                  state.developmentData = newState;

                  // Update query development data flag
                  state.query = {
                    ...state.query,
                    development: state.developmentData,
                  };
                },
                false,
                actionLog('updateDevelopmentData', newState),
              );
            },

            updateIncludeTryItNow: (newState: boolean) => {
              set(
                state => {
                  state.includeTryItNow = newState;

                  // Update query with include try it now flag
                  state.query = {
                    ...state.query,
                    tryItNow: newState,
                  };
                },
                false,
                actionLog('updateIncludeTryItNow', newState),
              );
            },

            updateMetricsPageConfig: (nextConfig: MetricsPageConfig) => {
              set(
                state => {
                  state.metricsPageConfig = {
                    ...state.metricsPageConfig,
                    ...nextConfig,
                  };

                  // Set initial query params for graph and table
                  state.graphQuery = qs.parse(nextConfig.graph.query) as Partial<MetricsFilters>;
                  state.tableQuery = qs.parse(nextConfig.table.query) as Partial<MetricsFilters>;
                },
                false,
                actionLog('updateMetricsPageConfig', nextConfig),
              );
            },

            updateQuery: (queryType, newParams) => {
              set(
                state => {
                  if (!['graphQuery', 'query', 'tableQuery'].includes(queryType)) {
                    throw new Error('Invalid queryType provided to updateQuery');
                  }

                  Object.entries(newParams).forEach(([key, value]) => {
                    if (value === null) {
                      delete state[queryType][key];
                    } else {
                      state[queryType][key] = value;
                    }
                  });
                },
                false,
                actionLog('updateQuery', { queryType, newParams }),
              );

              // Update the selected date range key anytime we update the query params
              get().updateSelectedDateRangeKey();
            },

            updateRoute: ({ pathname, search, prevPathname }) => {
              set(
                state => {
                  state.pathname = pathname;
                  state.search = search;
                  state.prevPathname = prevPathname;
                },
                false,
                actionLog('updateRoute', { pathname, search }),
              );

              // Update the selected date range key after updating route
              get().updateSelectedDateRangeKey();
              get().updateDateRanges();
            },

            updateSelectedDateRangeKey: () => {
              set(
                state => {
                  const { dateRanges } = get();

                  if (get().shouldLimitDateRanges()) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { enabled, ...queryPayload } = dateRangeDefaults.day;

                    // Update the query object with the default day range values
                    Object.entries(queryPayload).forEach(([key, value]) => {
                      if (value === null) {
                        delete state.query[key];
                      } else {
                        state.query[key] = value;
                      }
                    });
                  }

                  state.selectedDateRangeKey = getSelectedDateRangeKey({
                    dateRanges,
                    rangeLength: Number(state.query.rangeLength) || 0,
                    resolution: state.query.resolution || '',
                  });
                },
                false,
                actionLog('updateSelectedDateRangeKey'),
              );
            },
          };
        },
        {
          // Persist the store to localStorage for rehydration on page reloads
          name: 'MetricsStore',
          onRehydrateStorage: () => state => {
            state?.updateHasHydrated(true);
          },
          partialize: (state): Partial<MetricsStoreState> => {
            /**
             * On rehydration, only persist necessary state data:
             * customerUsage, dateRanges, isSetupComplete - these are updated on initialize() but are okay to hydrate for any initial dependent state
             * includeTryItNow, developmentData - should be persisted because it's a user preference
             */
            return {
              customerUsage: state.customerUsage,
              dateRanges: state.dateRanges,
              developmentData: state.developmentData,
              includeTryItNow: state.includeTryItNow,
              isSetupComplete: state.isSetupComplete,
            };
          },
        },
      ),
    ),
    { name: 'MetricsStore' },
  ),
);

/**
 * Bound react hook to access our Metrics store. Must be called within a React
 * component. To access the store outside of React, use `metricsStore` instead.
 * @example
 * import { useMetricsStore } from '@core/store';
 *
 * function Component() {
 *   const dateRanges = useMetricsStore(s => s.dateRanges);
 * }
 */
export const useMetricsStore = createBoundedUseStore(metricsStore);

export * from './InitializeMetricsStore';
export * from './MyDevelopers';
