import type { SuperHubStore } from '..';
import type { GitSidebarCategory, GitSidebarQueryString } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React, { useEffect, useRef } from 'react';

import useReadmeApi from '@core/hooks/useReadmeApi';

import { useSuperHubStore } from '..';

/**
 * Empty list to use as the default fallback when SWR hook is in transit.
 */
const emptyArray = [];

/**
 * Hash of route sections to its respective sidebar page type.
 */
export const superHubSidebarTypes: Record<
  NonNullable<SuperHubStore['routeSection']>,
  GitSidebarQueryString['page_type'] | null
> = {
  changelog: 'changelog',
  docs: 'guide',
  page: 'custom_page',
  recipes: null,
  reference: 'reference',
};

interface ConnectSuperHubSidebarToApiProps {
  children: React.ReactNode;
  /**
   * Very first sidebar data that is populated during SSR and the first CSR.
   */
  initialSidebar: GitSidebarCategory[] | null;
}

/**
 * Connects our `SuperHubStore` sidebar slice to the git sidebar API endpoint to
 * continually hydrate its data and keeps it in sync.
 */
export function ConnectSuperHubSidebarToApi({ children, initialSidebar }: ConnectSuperHubSidebarToApiProps) {
  const [apiBaseUrl, initialize, routeSection] = useSuperHubStore(s => [
    s.apiBaseUrl,
    s.sidebar.initialize,
    s.routeSection,
  ]);
  const initialSidebarRef = useRef(initialSidebar);

  // Validate sidebar data only when a valid page type exists.
  const pageType = routeSection ? superHubSidebarTypes[routeSection] : null;
  const apiUrl = pageType ? `${apiBaseUrl}/sidebar?page_type=${pageType}` : null;
  const {
    data,
    error = null,
    isLoading,
    swrKey,
  } = useReadmeApi<GitSidebarCategory[]>(apiUrl, {
    swr: {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    },
  });

  useEffect(() => {
    initialize({
      data: data || initialSidebarRef.current || emptyArray,
      error,
      isLoading,
      swrKey,
    });

    // After first connection is established to our SWR endpoints, discard the
    // initial value refs b/c we no longer need it as a fallback.
    if (!isLoading) {
      initialSidebarRef.current = null;
    }
  }, [data, error, initialize, isLoading, swrKey]);

  return <>{children}</>;
}
